import React from 'react';
import bcg from '../img/bcg-4.jpg';
import SwiperSlider from './SwiperSlider';
import YtSwiper from './YtSwiper';
import YoutubeEmbed from './YoutubeEmbed';
import SwiperSliderOragnizatorzy from './SwiperSliderOrganizatorzy';
import SwiperSliderAkcjeWspiera from './SwiperSliderAkcjeWspiera';
import SwiperSliderPartnerzy from './SwiperSliderPartnerzy';
import konkursEnd from '../img/komunikat_ztn.jpg';

function SectionSix() {
  return (
    <div id='movie' className='px-4'>
      <div
        className='mt-40 py-8 px-4 md:p-20 rounded-xl max-w-7xl mx-auto text-center swiper-nav'
        style={{
          backgroundImage: `url(${bcg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <p className='text-xl text-white mb-4'>
          Oglądaj „Lombard. Życie pod zastaw”, PON-PT o 19.00 i w SOB od 15.00
          na TV Puls
        </p>
        <p className='text-center text-2xl md:text-4xl text-white'>
          Odcinki specjalne #ZnamTeNumery
        </p>

        <div className='h-2 bg-white w-1/3 md:w-1/6 mt-8 mb-12 block mx-auto'></div>
        {/* <button className='bg-white rounded-lg px-8 py-3 text-[#ED6D28] shadow-lg'>
          Zobacz zwiastun
        </button> */}
        <YtSwiper />
        <div className='block md:w-1/2 mx-auto mt-4'>
          <YoutubeEmbed embedId='6MmqH1ucNWE?si=EifQEA3_YG76ENPh' />
        </div>
      </div>

      {/* <div
        id='kgw'
        className='mt-40 py-8 px-4 md:p-20 rounded-xl max-w-7xl mx-auto text-center'
      >
        <p className='text-center text-4xl lg:text-5xl font-bold block w-fit mx-auto'>
          Konkurs dla Kół Gospodyń Wiejskich
        </p>
        <div className='h-2 bg-[#ED6D28] w-1/6 mt-8 mb-12 block mx-auto'></div>
        <div className=''>
          <img
            src={konkursEnd}
            alt='konkurs'
            className='block mx-auto w-full rounded-xl'
          />
        </div>

         <div className='mt-8 py-28 px-4 rounded-2xl bg-[#FDF7F3] com-before'>
          <p className='font-bold text-2xl'>
            {' '}
            Z przyjemnością informujemy, że laureatem konkursu zostało
          </p>
          <p className='font-bold text-4xl'>
            <br /> KGW w Płatkownicy.
          </p>{' '}
          <p className='mt-8 font-bold text-2xl mb-8'> Gratulujemy!</p>
          <p className='font-bold mt-12 text-2xl mb-4'>
            Odpowiedź wyślij na adres:
          </p>
          <p>
            <a
              href='mailto:konkursy@pulstv.pl?subject=Konkurs_KGW'
              className='text-blue-500 hover:text-blue-700 text-xl'
            >
              konkursy@pulstv.pl
            </a>{' '}
            <br />z dopiskiem "Konkurs KGW" i informacją do którego Koła
            należysz <br />
          </p>
          <p className='mt-4'>
            <a
              href='https://znamtenumery.pl/14-12-2023REGULAMIN_KONKURSU_ZNAM_TE_NUMERY.pdf'
              target='_blank'
              className='text-blue-400'
            >
              Regulamin konkursu
            </a>
          </p>
          <p className='mt-4'>
            <a
              href='https://znamtenumery.pl/Zmiany-do-Regulaminu-Konkurs-Znam-te-numery-2024.pdf'
              target='_blank'
              className='text-blue-400'
            >
              Zmiany do Regulamin konkursu
            </a>
          </p>
        </div> 
      </div> */}
      <div className='hidden md:block p-20 rounded-xl max-w-7xl mx-auto text-center'>
        <SwiperSliderOragnizatorzy />
        <SwiperSliderAkcjeWspiera />
        <SwiperSliderPartnerzy />
      </div>
      <div className='block md:hidden md:mt-20 p-2 rounded-xl max-w-7xl mx-auto text-center'>
        <SwiperSlider />
      </div>
    </div>
  );
}

export default SectionSix;
